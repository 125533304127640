import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ProfileForm from "../account/profile/ProfileForm";
import Input from "../common/components/form/Input";
import Button from "../common/components/Button";
import Checkbox from "../common/components/form/Checkbox";
import Select from "../common/components/form/Select";
import Config from "../config";
import Ajax from "../common/ajax";
import Waiting from "../common/containers/Waiting";
import {AlertError, AlertSuccess} from "../common/components/Alert";
import {showDjangoValidationMessages} from "../common/validation";
import HtmlUtils from "../common/htmlutils";

class AdvisoryLeadForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            next: false,
            lead: null,
            form: {
                research_title: '',
                formation: '',
                class_types: [],
                work_types: [],
            },
            success: false,
            error: false,
            workTypes: [],
            formations: [],
            processing: 0,
        };
        this.onInputChange = this.onInputChange.bind(this);
        this.onWorkTypeChecked = this.onWorkTypeChecked.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.fetchWorkTypes();
        this.fetchFormations();
    }

    componentDidUpdate(prevProps, prevState){
        if (this.formRef.current && !prevState.next && this.state.next) {
            HtmlUtils.scrollFirstScrollableToTop(this.formRef.current);
        }
    }

    fetchWorkTypes() {
        this.setState(state => ({ ...state, processing: state.processing + 1 }));
        const url = `${Config.apiHost}advisory-work-types/`;
        Ajax.get(url).done(pagination => {
            this.setState(state => ({
                ...state,
                processing: state.processing - 1,
                workTypes: pagination.results,
            }));
        });
    }

    fetchFormations() {
        this.setState(state => ({ ...state, processing: state.processing + 1 }));
        const url = `${Config.apiHost}advisory-leads/formations/`;
        Ajax.get(url).done(formations => {
            this.setState(state => ({
                ...state,
                processing: state.processing - 1,
                formations: formations.map(f => ({value: f[0], label: f[1]})),
            }));
        });
    }

    onInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(state => ({
            ...state,
            form: {
                ...state.form,
                [name]: value
            }
        }));
    }

    onWorkTypeChecked(workType, checked) {
        const types = this.state.form.work_types.slice();
        if (checked) {
            types.push(workType.id);
        } else {
            const index = types.findIndex(i => i === workType.id);
            types.splice(index, 1);
        }
        this.setState(state => ({
            ...state,
            form: {
                ...state.form,
                work_types: types,
            }
        }));
    }

    onSubmit(evt) {
        this.setState(state => ({ ...state, processing: state.processing + 1 }));
        evt.preventDefault();
        if (this.state.form.work_types.length === 0) {
            alert('É necessário marcar ao menos um tipo de trabalho.');
            return;
        }
        console.log(this.state.form);
        const url = `${Config.apiHost}advisory-leads/`;
        Ajax.post(url, this.state.form).done(lead => {
            this.setState(state => ({...state, success: true, lead}));
        }).fail((jqXHR) => {
            this.setState(state => ({...state, error: true}));
            showDjangoValidationMessages(jqXHR.responseJSON);
        }).always(() => {
            this.setState(state => ({...state, processing: state.processing - 1}));
        });
    }

    render() {
        let body;

        if (this.state.success) {
            body = (
                <>
                    <AlertSuccess>
                        Sua requisição para assessoria acadêmica foi enviada com sucesso.
                        {' '}
                        Nossa coordenação acadêmica já foi informada e entrará em contato pelo telefone informado:
                        {' '}
                        <b>{this.state.lead.user_profile.phone_number}</b>.
                    </AlertSuccess>
                    {this.props.successElement}
                </>
            );
        } else {
            body = (
                <div
                    className={this.props.asPanel ? 'panel panel-border-color panel-border-color-primary' : ''}
                    ref={this.formRef}
                >
                    {this.props.asPanel && (
                        <div className="panel-heading">
                            {this.state.next ?
                                'Informe sua necessidade e clique em Enviar' :
                                'Confirme seus dados e clique em Avançar'
                            }
                        </div>
                    )}
                    <div className={this.props.asPanel ? 'panel-body' : ''}>
                        {this.state.error && (
                            <AlertError>
                                Não foi possível enviar sua requisição. Se o error persistir contate nosso suporte.
                            </AlertError>
                        )}

                        {!this.state.next && (
                            <ProfileForm
                                excludeFields={['occupation',, 'broader_area', 'area']}
                                saveButtonLabel="Avançar >>"
                                phoneLabel="Telefone (WhatsApp)"
                                onSuccess={() => this.setState(state => ({...state, next: true}))}
                            />
                        )}

                        {this.state.next && (
                            <form onSubmit={this.onSubmit}>
                                <Input
                                    name="research_title"
                                    value={this.state.form.research_title || ''}
                                    id="id_research_title"
                                    label="Título da pesquisa"
                                    maxLength="500"
                                    required={true}
                                    onChange={this.onInputChange}
                                />

                                <Select
                                    id="formation"
                                    name="formation"
                                    label="Formação"
                                    required={true}
                                    options={this.state.formations}
                                    onChange={this.onInputChange}
                                />

                                <h4>Para quais documentos tem interesse? Se necessário, marque mais de um item.</h4>

                                {this.state.workTypes.map(s => (
                                    <Checkbox
                                        id={s.id}
                                        key={s.id}
                                        checked={!!this.state.form.work_types.find(i => i === s.id)}
                                        label={s.name}
                                        onChange={(evt) => this.onWorkTypeChecked(s, evt.target.checked)}
                                        asFormGroup={false}
                                    />
                                ))}

                                <br/>

                                <Button
                                    size="lg"
                                    type="default"
                                    onClick={() => this.setState(state => ({...state, next: false}))}
                                >
                                    {'<< Voltar'}
                                </Button>

                                {' '}

                                <Button size="lg" submit={true}>
                                    Enviar
                                </Button>
                            </form>
                        )}
                    </div>
                </div>
            );
        }

        return (
            <Waiting isProcessing={!!this.state.processing}>
                {body}
            </Waiting>
        );
    }
}

AdvisoryLeadForm.propTypes = {
    asPanel: PropTypes.bool,
    successElement: PropTypes.element,
};

AdvisoryLeadForm.defaultProps = {
    asPanel: true,
};

export default AdvisoryLeadForm;