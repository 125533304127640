import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';

class AvisoGhostwriting extends Component {
  render() {
    const { i18n } = this.props;
    return (
      <p>
        <i>
          {i18n.t(
            'ATENÇÃO! O FastFormat não disponibiliza serviços de escrita (ghostwriting) de TCC ou qualquer outro tipo de documento. Tais serviços configuram prática notadamente antiética e pode configurar crime de plágio e/ou falsidade ideológica.'
          )}
        </i>
      </p>
    );
  }
}

export default withNamespaces()(AvisoGhostwriting);
