import React, {Component} from 'react';
import Config from "../config";
import Ajax from "../common/ajax";
import LazyImage from "../common/components/LazyImage";
import Col from "../common/containers/Col";

class AdvisoryAdvisersCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            advisers: [],
        };
        this._mounted = false;
    }

    componentDidMount() {
        this._mounted = true;
        this.fetchAdvisors();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    fetchAdvisors() {
        const url = `${Config.apiHost}advisory-advisers/`;
        Ajax.get(url).done(pagination => {
            if (this._mounted) {
                this.setState(state => ({
                    ...state,
                    advisers: pagination.results,
                }));
            }
        });
    }

    render() {
        if (!this.state.advisers || !this.state.advisers.length) {
            return null;
        }

        return (
            <div
                className="carousel slide quote-carousel"
                data-ride="carousel"
                id="quote-carousel-advisers"
                style={{marginTop: '0'}}
            >
                <ol className="carousel-indicators">
                    {this.state.advisers.map((t, idx) => {
                        return (
                            <li data-target="#quote-carousel-advisers"
                                data-slide-to={idx}
                                className={`${t.is_first ? 'active' : ''}`} key={idx}>
                                <LazyImage
                                    src={t.picture}
                                    alt={t.name}
                                    title={t.name}
                                    className="img-responsive"
                                />
                            </li>
                        );
                    })}
                </ol>
                <div className="carousel-inner text-center">
                    {this.state.advisers.map((t, idx) => {
                        return (
                            <div className={`item ${t.is_first ? 'active' : ''}`} key={idx}>
                                <Col md={8} mdOffset={2}>
                                    <blockquote>
                                        <p>
                                            {t.prof_prefix} {t.name}
                                            <br/>
                                            <span className="text-muted">{t.formation_label}</span>
                                            <br/>
                                            <a href={t.cv_url} target="_blank">Currículo Lattes</a>
                                        </p>
                                    </blockquote>
                                </Col>
                            </div>
                        );
                    })}
                    <a data-slide="prev" href="#quote-carousel-advisers" className="left carousel-control">
                        <i className="fas fa-chevron-left"/>
                    </a>
                    <a data-slide="next" href="#quote-carousel-advisers" className="right carousel-control">
                        <i className="fas fa-chevron-right"/>
                    </a>
                </div>
            </div>
        );
    }
}

export default AdvisoryAdvisersCarousel;