import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from "../../common/components/Button";
import AdvisoryLeadForm from "../AdvisoryLeadForm";
import Modal from "../../common/containers/Modal";

class AdvisoryService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showText: false,
        };
    }

    getFirstParagraph() {
        return this.props.service.description.split('\n')[0];
    }

    getAllText() {
        return this.props.service.description.replaceAll('\n', '<br/>');
    }

    toggleAllText() {
        this.setState(state => ({...state, showText: !state.showText}));
    }

    render() {
        const {service} = this.props;
        return (
            <>
                <div className="pricing-table pricing-table-primary">
                    <div className="pricing-table-image">
                    </div>
                    <div className="pricing-table-title">
                        {service.name}
                    </div>
                    <div className="panel-divider panel-divider-xl"/>
                    <p className="pricing-table-features">
                        {!this.state.showText && (
                            this.getFirstParagraph()
                        )}
                        {this.state.showText && (
                            <span dangerouslySetInnerHTML={{__html: this.getAllText()}}/>
                        )}
                        {' '}
                        <a
                            role="button"
                            onClick={this.toggleAllText.bind(this)}
                        >
                            {this.state.showText ? '[-menos]' : '[+mais]'}
                        </a>
                    </p>
                    <br/>
                    <Button
                        onClick={() => this.setState(state => ({...state, showModal: true}))}
                        size="lg"
                    >
                        Solicitar
                    </Button>
                </div>

                <Modal
                    show={this.state.showModal}
                    width="large"
                    title="Solicitar orçamento para assessoria acadêmica"
                    onCancel={() => this.setState(state => ({...state, showModal: false}))}
                >
                    <AdvisoryLeadForm asPanel={false} />
                </Modal>
            </>
        );
    }
}

AdvisoryService.propTypes = {
    service: PropTypes.object.isRequired,
};

export default AdvisoryService;
