import React, {Component} from 'react';
import Config from "../config";
import LazyImage from "../common/components/LazyImage";
import Ajax from "../common/ajax";

class AdvisoryTestimonials extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testimonials: [],
        };
        this._mounted = false;
    }

    componentDidMount() {
        this._mounted = true;
        this.fetchTestimonials();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    fetchTestimonials() {
        const url = `${Config.apiHost}advisory-testimonials/`;
        Ajax.get(url).done(testimonials => {
            if (this._mounted) {
                this.setState(state => ({...state, testimonials}));
            }
        });
    }

    render() {
        if (!this.state.testimonials || !this.state.testimonials.length) {
            return null;
        }

        return (
            <>
                <h2 className="text-center">
                    Depoimentos dos nossos alunos
                </h2>
                <div className="carousel slide quote-carousel" data-ride="carousel" id="quote-carousel-testimonials">
                    <ol className="carousel-indicators">
                        {this.state.testimonials.map((t, idx) => {
                            return (
                                <li data-target="#quote-carousel-testimonials"
                                    data-slide-to={idx}
                                    className={`${t.is_first ? 'active' : ''}`} key={idx}>
                                    <LazyImage
                                        src={t.picture}
                                        alt={t.name}
                                        title={t.name}
                                        className="img-responsive"
                                    />
                                </li>
                            );
                        })}
                    </ol>
                    <div className="carousel-inner text-center">
                        {this.state.testimonials.map((t, idx) => {
                            const workType = t.work_type ? t.work_type.name : t.work_type_other;
                            return (
                                <div className={`item ${t.is_first ? 'active' : ''}`} key={idx}>
                                    <blockquote>
                                        <div className="row">
                                            <div className="col-sm-10 col-sm-offset-1">
                                                <i className="fas fa-star text-warning"/>
                                                <i className="fas fa-star text-warning"/>
                                                <i className="fas fa-star text-warning"/>
                                                <i className="fas fa-star text-warning"/>
                                                <i className="fas fa-star text-warning"/>
                                                <p>{t.testimonial}</p>
                                                <small>
                                                    {t.name}
                                                    <br/>
                                                    <i>{t.research_title}</i>
                                                    <br/>
                                                    <i>{t.institution}</i>
                                                    <br/>
                                                    <i>{workType}</i>
                                                </small>
                                            </div>
                                        </div>
                                    </blockquote>
                                </div>
                            );
                        })}
                        <a data-slide="prev" href="#quote-carousel-testimonials" className="left carousel-control">
                            <i className="fas fa-chevron-left"/>
                        </a>
                        <a data-slide="next" href="#quote-carousel-testimonials" className="right carousel-control">
                            <i className="fas fa-chevron-right"/>
                        </a>
                    </div>
                </div>
            </>
        );
    }
}

export default AdvisoryTestimonials;