import React from 'react';
import PropTypes from 'prop-types';
import Waiting from '../../common/containers/Waiting';
import Ajax from '../../common/ajax';
import Config from '../../config';
import * as validation from '../../common/validation';
import Row from '../../common/containers/Row';
import Col from '../../common/containers/Col';
import Input from '../../common/components/form/Input';
import Select from '../../common/components/form/Select';
import { withNamespaces } from 'react-i18next';
import { AppContext } from '../../context/global';

class ProfileForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {},
      processing: false,
      broaderAreas: [],
      areas: [],
      occupations: [],
      degrees: [],
      saved: false,
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.save = this.save.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.submitButton = null;
  }

  componentDidMount() {
    if (this.props.saveFuncRef) {
      this.props.saveFuncRef(this.save);
    }

    if (this.showField('broader_area')) {
      this.fetchBroaderAreas();
    }

    if (this.showField('occupation')) {
      this.fetchOccupations();
    }

    if (this.showField('education')) {
      this.fetchDegrees();
    }

    this.fetchProfile();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.showField('broader_area') &&
      this.state.profile.broader_area !== prevState.profile.broader_area
    ) {
      this.fetchAreas();
    }
  }

  fetchProfile() {
    this.setState({
      ...this.state,
      processing: true,
    });
    const url = `${Config.apiHost}profiles/current/`;
    Ajax.get(url).done((profile) => {
      this.setState({
        ...this.state,
        profile: profile,
        processing: false,
      });
    });
  }

  fetchBroaderAreas() {
    const url = `${Config.apiHost}cnpq-broader-areas/`;
    Ajax.get(url).done((broaderAreas) => {
      this.setState({
        ...this.state,
        broaderAreas: broaderAreas.results,
      });
    });
  }

  fetchAreas() {
    if (!this.state.profile.broader_area) {
      return;
    }

    const url = `${Config.apiHost}cnpq-broader-areas/${this.state.profile.broader_area}/areas/`;
    Ajax.get(url).done((areas) => {
      this.setState({
        ...this.state,
        areas: areas,
      });
    });
  }

  fetchOccupations() {
    const url = `${Config.apiHost}profiles/occupations/`;
    Ajax.get(url).done((occupations) => {
      this.setState({
        ...this.state,
        occupations: occupations,
      });
    });
  }

  fetchDegrees() {
    const url = `${Config.apiHost}profiles/degrees/`;
    Ajax.get(url).done((degrees) => {
      this.setState({
        ...this.state,
        degrees: degrees,
      });
    });
  }

  onInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      ...this.state,
      profile: {
        ...this.state.profile,
        [name]: value,
      },
    });
  }

  showField(field) {
    return (
      this.props.fields.indexOf(field) > -1 &&
      this.props.excludeFields.indexOf(field) === -1
    );
  }

  onSubmit(evt) {
    evt.preventDefault();

    validation.clearDjangoValidationMessages();

    this.setState({
      ...this.state,
      processing: true,
      saved: false,
    });

    const url = `${Config.apiHost}profiles/${this.state.profile.id}/`;

    const data = {};
    this.props.fields.forEach(
      (field) => (data[field] = this.state.profile[field])
    );

    Ajax.patch(url, data)
      .done((response) => {
        this.setState({
          ...this.state,
          processing: false,
          saved: true,
        });
        this.props.onSuccess && this.props.onSuccess();
      })
      .fail((response) => {
        validation.showDjangoValidationMessages(response.responseJSON);
        this.setState({
          ...this.state,
          processing: false,
        });
        this.props.onFail && this.props.onFail();
      });
  }

  save() {
    /* Hack to use the native form validation. */
    this.submitButton.click();
  }

  render() {
    const { i18n } = this.props;

    return (
      <Waiting isProcessing={this.state.processing}>
        <form onSubmit={this.onSubmit}>
          {this.showField('fullname') && (
            <Input
              name="fullname"
              value={this.state.profile.fullname || ''}
              id="id_fullname"
              label={i18n.t('Nome completo')}
              placeholder={i18n.t('Nome completo')}
              maxLength="500"
              required={true}
              onChange={this.onInputChange}
            />
          )}

          {this.showField('phone_number') && (
            <Row>
              <Col md={5}>
                <Input
                  name="phone_number"
                  id="id_phone_number"
                  label={this.props.phoneLabel || i18n.t('Número de celular')}
                  help={i18n.t(
                    'Adicione um número de celular para aumentar sua segurança.'
                  )}
                  value={this.state.profile.phone_number || ''}
                  onChange={this.onInputChange}
                  mask="(99) 99999-9999"
                  required={true}
                />
              </Col>
            </Row>
          )}

          {this.showField('occupation') && (
            <Select
              name="occupation"
              required={true}
              id="id_occupation"
              label={i18n.t('Ocupação')}
              value={this.state.profile.occupation}
              options={this.state.occupations.map((d) => ({
                label: d[1],
                value: d[0],
              }))}
              onChange={this.onInputChange}
            />
          )}

          {this.showField('education') &&
            this.state.profile.occupation === 'student_pg' && (
              <Select
                name="education"
                id="id_education"
                label={i18n.t('Grau de educação')}
                help={i18n.t(
                  'Último grau de educação (em progresso ou finalizado)'
                )}
                value={this.state.profile.education}
                options={this.state.degrees.map((d) => ({
                  label: d[1],
                  value: d[0],
                }))}
                onChange={this.onInputChange}
              />
            )}

          {this.showField('broader_area') && (
            <Select
              id="id-broader-area"
              label={i18n.t('Área de conhecimento')}
              required={true}
              name="broader_area"
              value={this.state.profile.broader_area}
              options={this.state.broaderAreas.map((area) => ({
                label: area.name,
                value: area.id,
              }))}
              onChange={this.onInputChange}
            />
          )}

          {this.showField('area') && (
            <Select
              id="id-area"
              required={true}
              name="area"
              value={this.state.profile.area}
              options={this.state.areas.map((area) => ({
                label: area.name,
                value: area.id,
              }))}
              onChange={this.onInputChange}
            />
          )}

          {this.showField('course') && (
            <Input
              name="course"
              id="id_course"
              label={i18n.t('Nome do curso')}
              maxLength="500"
              autoComplete="fuck-off"
              required={true}
              value={this.state.profile.course || ''}
              onChange={this.onInputChange}
            />
          )}

          {this.showField('institution') && (
            <Input
              name="institution"
              id="id_institution"
              label={i18n.t('Instituição de ensino')}
              maxLength="500"
              autoComplete="fuck-off"
              required={true}
              value={this.state.profile.institution || ''}
              onChange={this.onInputChange}
            />
          )}

          {/*Hack to use the native form validation.*/}
          <button
            type="submit"
            className="btn btn-primary btn-lg"
            style={{ display: this.props.saveFuncRef ? 'none' : '' }}
            ref={(el) => (this.submitButton = el)}
            disabled={this.state.processing}
          >
            {this.props.saveButtonLabel || i18n.t('Salvar')}
          </button>
          {this.state.saved && (
            <p className="text-success">
              <i className="fas fa-check" />{' '}
              {i18n.t('Informações salvas com sucesso.')}
            </p>
          )}
        </form>
      </Waiting>
    );
  }
}

const FIELDS = [
  'fullname',
  'occupation',
  'education',
  'institution',
  'course',
  'country',
  'state',
  'city',
  'phone_number',
  'company',
  'broader_area',
  'area',
];

ProfileForm.contextType = AppContext;

ProfileForm.propTypes = {
  /**
   * Fields which must be showed for update.
   */
  fields: PropTypes.arrayOf(PropTypes.string),
  excludeFields: PropTypes.arrayOf(PropTypes.string),
  /**
   * A function which receives a reference for the save function. The save
   * function return a jQuery xhr reference.
   */
  saveFuncRef: PropTypes.func,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  saveButtonLabel: PropTypes.string,
  phoneLabel: PropTypes.string,
};

ProfileForm.defaultProps = {
  fields: FIELDS,
  excludeFields: [],
};

export default withNamespaces()(ProfileForm);
