import React, { Component } from 'react';
import Content from '../../common/containers/Content';
import Col from '../../common/containers/Col';
import Row from '../../common/containers/Row';
import ClassService from './ClassService';
import Config from '../../config';
import Ajax from '../../common/ajax';
import Waiting from '../../common/containers/Waiting';
import PrivatePage from '../../common/containers/PrivatePage';
import AdvisoryAdvisersCarousel from '../AdvisoryAdvisersCarousel';
import AdvisoryService from './AdvisoryService';
import Modal from '../../common/containers/Modal';
import LazyImage from '../../common/components/LazyImage';
import PaginationWithClick from '../../common/components/PaginationWithClick';
import AdvisoryTestimonials from '../AdvisoryTestimonials';
import AvisoGhostwriting from '../../common/AvisoGhostwriting';

class AdvisoryServicesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: 0,
      formations: [],
      classServices: [],
      advisoryServices: [],
      showFaqAulas: false,
      showFaqAssessoria: false,
      advisersPagination: {},
      showAdvisers: false,
    };
    this.fetchAdvisers = this.fetchAdvisers.bind(this);
  }

  componentDidMount() {
    this.fetchClassServices();
    this.fetchAdvisoryServices();
    this.fetchAdvisers();
  }

  fetchClassServices() {
    this.setState((state) => ({ ...state, processing: state.processing + 1 }));
    const url = `${Config.apiHost}advisory-services/?service_type=class`;
    Ajax.get(url).done((classServices) => {
      this.setState((state) => ({
        ...state,
        processing: state.processing - 1,
        classServices,
      }));
    });
  }

  fetchAdvisoryServices() {
    this.setState((state) => ({ ...state, processing: state.processing + 1 }));
    const url = `${Config.apiHost}advisory-services/?service_type=advisory`;
    Ajax.get(url).done((advisoryServices) => {
      this.setState((state) => ({
        ...state,
        processing: state.processing - 1,
        advisoryServices,
      }));
    });
  }

  fetchAdvisers(url = `${Config.apiHost}advisory-advisers/`) {
    this.setState((state) => ({ ...state, processing: state.processing + 1 }));
    Ajax.get(url).done((advisersPagination) => {
      this.setState((state) => ({
        ...state,
        processing: state.processing - 1,
        advisersPagination,
      }));
    });
  }

  render() {
    let advisorySubLists = [];
    const advisoryServices = this.state.advisoryServices.slice();
    while (advisoryServices.length > 0) {
      advisorySubLists.push(advisoryServices.splice(0, 3));
    }
    return (
      <PrivatePage hasAside={false} title="Assessoria acadêmica">
        <Waiting isProcessing={!!this.state.processing}>
          <Content>
            <Row>
              <Col md={10} mdOffset={1}>
                <h1 className="text-center">
                  Serviços acadêmicos individualizados
                </h1>

                <h4 className="text-center">
                  Nós dispomos de uma equipe de docentes especializados que
                  atuam em áreas multidisciplinares e podem ajudar a esclarecer
                  suas dúvidas e assessorá-lo na construção do seu trabalho.{' '}
                  <a
                    role="button"
                    onClick={() =>
                      this.setState((state) => ({
                        ...state,
                        showAdvisers: true,
                      }))
                    }
                  >
                    Conheça todos os professores.
                  </a>
                </h4>
              </Col>
            </Row>

            <AdvisoryAdvisersCarousel />

            <Row>
              <Col md={10} mdOffset={1}>
                <h2 className="text-center">Assessoria acadêmica</h2>

                <h4 className="text-center">
                  Atendimento individual e personalizado; esclarecimento de
                  dúvidas de forma contínua via WhatsApp durante todo o
                  processo; professor com experiência na linha de pesquisa
                  abordada.{' '}
                  <a
                    role="button"
                    onClick={() =>
                      this.setState((state) => ({
                        ...state,
                        showFaqAssessoria: true,
                      }))
                    }
                  >
                    Saiba mais.
                  </a>
                </h4>

                <div className="text-center">
                  <AvisoGhostwriting />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={10} mdOffset={1}>
                <div className="row pricing-tables">
                  {advisorySubLists.map((row, idx) => {
                    return (
                      <Row key={`row-${idx}`}>
                        {row.map((p) => (
                          <div className="col-md-4" key={`advisory-${p.name}`}>
                            <AdvisoryService service={p} />
                          </div>
                        ))}
                      </Row>
                    );
                  })}
                </div>
              </Col>
            </Row>

            <div className="panel-divider panel-divider-xl" />

            <Row>
              <Col md={10} mdOffset={1}>
                <h2 className="text-center">Aulas on-line avulsas</h2>
                <h4 className="text-center">
                  Atendimento imediato; esclarecimento de dúvidas com um
                  professor especialista na sua área; essencial para o
                  aperfeiçoamento das próximas etapas da sua pesquisa.{' '}
                  <a
                    role="button"
                    onClick={() =>
                      this.setState((state) => ({
                        ...state,
                        showFaqAulas: true,
                      }))
                    }
                  >
                    Saiba mais.
                  </a>
                </h4>
              </Col>
            </Row>

            <Row>
              <Col md={10} mdOffset={1}>
                <div className="row pricing-tables">
                  {this.state.classServices.map((p) => (
                    <div className="col-md-4" key={p.name}>
                      <ClassService service={p} />
                    </div>
                  ))}
                </div>
              </Col>
            </Row>

            <AdvisoryTestimonials />
          </Content>
        </Waiting>

        <Modal
          title="Como funcionam as aulas on-line"
          show={this.state.showFaqAulas}
          onCancel={() =>
            this.setState((state) => ({ ...state, showFaqAulas: false }))
          }
        >
          <h4>O que é</h4>
          <p>
            As aulas on-line são indicadas para pessoas que necessitam de um
            atendimento urgente, disponibilizando-o de forma rápida, eficiente e
            eficaz, esclarecendo sobre a temática abordada e trabalho
            desenvolvido pelo aluno/pesquisador; sanando eventuais dúvidas no
            que se refere a definição de problemática; objetivos; justificativa;
            hipóteses e introdução; esclarecimento nos aspectos relacionados a
            construção da pesquisa e base de dados que possam auxiliar nas
            etapas da pesquisa; além de orientação no que concerne aos aspectos
            metodológicos; desenvolvimento ou aperfeiçoamento das etapas da
            pesquisa.
          </p>
          <h4>Metodologia</h4>
          <p>
            Basta escolher o tipo de aula que mais se encaixa nas suas
            necessidades, comprar, e em no máximo 24h um dos nossos professores
            especialistas na área desejada entrará em contato para marcar o
            melhor dia e horário para a realização do atendimento on-line. As
            aulas são realizadas via Hangout para compartilhamento de tela e
            esclarecimento de dúvidas.
          </p>
        </Modal>

        <Modal
          title="Como funcionam as assessorias acadêmicas"
          show={this.state.showFaqAssessoria}
          onCancel={() =>
            this.setState((state) => ({ ...state, showFaqAssessoria: false }))
          }
        >
          <h4>O que é</h4>
          <p>
            A assessoria acadêmica é direcionada a alunos, professores e
            pesquisadores que sentem dificuldade no desenvolvimento da escrita
            científica, metodologia e demais aspectos relacionados à elaboração,
            estrutura e normalização do trabalho.
          </p>

          <p>
            São pacotes personalizados especialmente para pessoas que desejam
            aperfeiçoamento da escrita durante a elaboração de textos acadêmicos
            e científicos no nível de graduação, pós-graduação lato sensu e
            stricto sensu, além de pesquisadores que buscam aprimorar o seu
            currículo através de publicações em periódicos e outros meios de
            comunicação científica.
          </p>

          <h4>Metodologia</h4>

          <p>
            Basta preencher o formulário on-line que um dos nossos coordenadores
            entrará em contato, analisará o seu caso e lhe enviará um orçamento.
            Em caso de aprovação, disponibilizaremos um Professor especialista
            que lhe ajudará quanto a:
          </p>

          <ul>
            <li>esclarecimento de dúvidas sobre normalização;</li>
            <li>análise metodológica e escrita do texto em desenvolvimento;</li>
            <li>averiguação e envio de relatório de plágio;</li>
            <li>checagem das referências bibliográficas utilizadas;</li>
            <li>
              ajuda na busca por material complementar que contextualizará o
              embasamento teórico da pesquisa;
            </li>
            <li>
              orientação quanto a estrutura dos parágrafos, períodos e sequência
              lógica do texto.
            </li>
          </ul>

          <p>
            Um acompanhamento individual e personalizado é realizado via
            WhatsApp durante toda a elaboração da pesquisa.
          </p>

          <p>
            A assessoria também inclui aulas on-line que são realizadas via
            Hangout para compartilhamento de tela e esclarecimento de dúvidas
            sobre a pesquisa em andamento.
          </p>

          <AvisoGhostwriting />
        </Modal>

        <Modal
          show={this.state.showAdvisers}
          title="Conheça os professores"
          onCancel={() =>
            this.setState((state) => ({ ...state, showAdvisers: false }))
          }
          width="large"
        >
          {this.state.advisersPagination.results &&
            this.state.advisersPagination.results.map((a) => (
              <Row>
                <Col md={3}>
                  <h3>
                    <LazyImage
                      src={a.picture}
                      alt={a.name}
                      style={{
                        borderRadius: '50%',
                        width: '128px',
                        height: '128px',
                      }}
                    />
                  </h3>
                </Col>
                <Col md={9}>
                  <h3>
                    {a.prof_prefix} {a.name}
                  </h3>
                  <p>{a.short_bio}</p>
                  <a href={a.cv_url} target="_blank">
                    Currículo
                  </a>
                </Col>
              </Row>
            ))}
          <PaginationWithClick
            handleClick={this.fetchAdvisers}
            pagination={this.state.advisersPagination}
          />
        </Modal>
      </PrivatePage>
    );
  }
}

export default AdvisoryServicesPage;
