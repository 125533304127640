import React, {Component} from 'react';
import {Redirect} from "react-router-dom";
import PropTypes from 'prop-types';
import Button from "../../common/components/Button";
import Modal from "../../common/containers/Modal";
import Select from "../../common/components/form/Select";
import Config from "../../config";
import Ajax from "../../common/ajax";
import {AlertError} from "../../common/components/Alert";
import URLS from "../../urls";
import LocaleUtils from "../../common/LocaleUtils";

class ClassService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModalFormation: false,
            formations: [],
            formation: '',
            processing: 0,
            error: false,
            invoice: null,
        };
        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.showModalFormation && this.state.showModalFormation) {
            this.fetchFormations();
        }
    }

    fetchFormations() {
        this.setState(state => ({...state, processing: state.processing + 1}));
        const url = `${Config.apiHost}advisory-services/formations/`;
        Ajax.get(url).done(formations => {
            this.setState(state => ({
                ...state,
                processing: state.processing - 1,
                formations: formations.map(f => {
                    f.label = f.label + ' - ' + 'R$ ' + LocaleUtils.currency(this.props.service[f.value]);
                    return f
                }),
            }));
        });
    }

    onInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState(state => ({
            ...state,
            [target.name]: value,
        }));
    }

    onSubmit(evt) {
        evt.preventDefault();
        this.setState(state => ({
            ...state,
            processing: state.processing + 1,
            error: false,
        }));
        const url = `${Config.apiHost}advisory-invoices/buy_class/`;
        const data = {
            service_name: this.props.service.name,
            formation: this.state.formation,
        };
        Ajax.post(url, data).done(invoice => {
            this.setState(state => ({
                ...state,
                invoice,
                processing: state.processing - 1,
                error: false,
            }));
        }).fail(jqXHR => {
            this.setState(state => ({
                ...state,
                processing: state.processing - 1,
                error: true,
            }));
        });
    }

    render() {
        if (this.state.invoice) {
            return <Redirect to={URLS.advisoryInvoice(this.state.invoice.id)} />
        }

        const {service} = this.props;
        return (
            <>
                <div className="pricing-table pricing-table-primary">
                    <div className="pricing-table-image">
                    </div>
                    <div className="pricing-table-title">
                        {service.name}
                    </div>
                    <div className="panel-divider panel-divider-xl"/>
                    <p className="pricing-table-features">
                        {service.description}
                    </p>
                    <p className="text-muted">
                        A partir de {LocaleUtils.currency(service.undergrad_student)} a hora-aula
                    </p>
                    {/*<div className="panel-divider panel-divider-xl"/>*/}
                    {/*<div className="pricing-table-price">*/}
                        {/*<span className="currency">r$</span>*/}
                        {/*<span className="value">{service.undergrad_student}</span>*/}
                        {/*<span className="frecuency">/hora-aula</span>*/}
                        {/*<br/>*/}
                        {/*<small>(graduação e especialização)</small>*/}
                    {/*</div>*/}
                    {/*<div className="pricing-table-price">*/}
                        {/*<span className="currency">r$</span>*/}
                        {/*<span className="value">{service.master_student}</span>*/}
                        {/*<span className="frecuency">/hora-aula</span>*/}
                        {/*<br/>*/}
                        {/*<small>(mestrado e doutorado)</small>*/}
                    {/*</div>*/}
                    <br/>
                    <Button onClick={() => this.setState(s => ({...s, showModalFormation: true}))} size="lg">
                        Selecionar
                    </Button>
                </div>

                <Modal
                    title={`Aula avulsa de ${service.name.toLowerCase()}`}
                    show={this.state.showModalFormation}
                    onCancel={() => this.setState(s => ({...s, showModalFormation: false}))}
                    isProcessing={!!this.state.processing}
                >
                    <form onSubmit={this.onSubmit}>
                        {this.state.error && (
                            <AlertError>
                                Não foi possível processar sua requisição, tente novamente.
                            </AlertError>
                        )}

                        <p>
                            Escolha a formação desejada
                        </p>
                        <Select
                            name="formation"
                            options={this.state.formations}
                            onChange={this.onInputChange}
                            value={this.state.formation}
                            required
                        />

                        <br/>

                        <Button size="lg" submit={true}>Prosseguir</Button>
                    </form>
                </Modal>
            </>
        );
    }
}

ClassService.propTypes = {
    service: PropTypes.object.isRequired,
};

export default ClassService;
